/* Demo Styles */
// Add spacing to Boostrap components for demo purpose
.template-demo {
  > .btn {
    @extend .mt-3;
    @extend .mr-2;
  }

  .slider-wrap {
    height: 100px;
  }

  > .btn-toolbar {
    @extend .mt-3;
    @extend .mr-3;
  }

  > .btn-group,
  >  .btn-group-vertical {
    @extend .mt-2;
    @extend .mr-2;
  }

  .progress {
    margin-top: 1.5rem;
  }

  .circle-progress {
    @extend .mt-2;
  }

  > h2,
   > h3,
   > h4,
   > h5,
   > h6,
  > h1 {
    border-top: 1px solid $border-color;
    padding: 0.5rem 0 0;
  }

  .ul-slider {
    &.noUi-horizontal {
      margin-top: 2rem;
    }

    &.noUi-vertical {
      margin-right: 2rem;
    }
  }

  > .dropdown {
    display: inline-block;
    @extend .mr-2;
    margin-bottom: 0.5rem;
  }

  nav {
    .breadcrumb {
      margin-bottom: 1.375rem;
    }

    &:last-child {
      .breadcrumb {
        margin-bottom: 0;
      }
    }
  }

  .editable-form {
    > .form-group {
      border-bottom: 1px solid $border-color;
      padding-bottom: 0.8rem;
      margin-bottom: 0.8rem;
    }
  }

  .circle-progress {
    padding: 15px;
  }

  .circle-progress-block {
    @extend .mb-3;
    @extend .px-2;
  }
}

.demo-modal {
  position: static;
  display: block;

  .modal-dialog {
    @extend .mt-0;

    &.modal-lg {
      max-width: 100%;
    }
  }
}

.loader-demo-box {
  @extend .border;
  @extend .border-secondary;
  @extend .rounded;
  width: 100%;
  height: 200px;
  @extend .d-flex;
  @extend .align-items-center;
}

.dropdown-menu-static-demo {
  height: 250px;
  margin-bottom: 50px;
  .dropdown-menu {
    z-index: 0;
  }
}

.rounded-legend {
  ul {
    li {
      list-style-type: none;
      color: $text-muted;
      font-size: .75rem;
      .legend-dots {
        width: 1rem;
        height: 1rem;
        border-radius: 100%;
        display: inline-block;
        vertical-align: text-bottom;
        margin-right: .5rem;
        .rtl & {
          margin-left: .5rem;
        }
      }
    }
  }
  &.legend-horizontal {
    @include display-flex;
    ul {
      li {
        display: inline-block;
        margin-right: 1.5rem;
        .rtl & {
          margin-right: auto;
          margin-left: 1.5rem;
        }
      }
    }
  }
  &.legend-top-right {
    ul {
      float: right;
      .rtl & {
        float: left;
      }
    }
  }
  &.legend-vertical {
    ul {
      li {
        margin-top: 1rem;
      }
    }
  }
}

.demo-chart {
  height: 300px;
  @media (max-width: 767px) {
    height: 200px;
  }
}

.chartjs-legend {
  .goals-chart {
    @extend .d-flex;
    @extend .justify-content-around;
    .item {
      @extend .d-flex;
      @extend .flex-column;
      @extend .align-items-center;
      .legend-label {
        width: 17px;
        height: 17px;
        border-radius: 17px;
        margin-bottom: 10px;
      }
    }
  }
}

.documentation {
  padding-top: 0;
  max-width: 100%;
  .content-wrapper {
    padding-top: 0;
    min-height: calc(100vh - #{$footer-height});
  }
}
.doc-table-contents,
.doc-header {
  position: fixed;
}
.doc-header {
  padding-top: 24px;
  padding-bottom: 24px;
  z-index: 2;
  background: $content-bg;
}

.doc-content {
  padding-top: 157px;
}

.doc-table-contents {
  overflow: auto;
  max-height: calc(100% - 157px);
  ul {
    li {
      margin-top: 10px;
    }
  }
}

.doc-credits {
  ul {
    li {
      margin-bottom: 10px;
      a {
        margin-left: .25rem;
      }
    }
  }
}

.demo-vector-map {
  width: 100%;
  height: 500px;
}

.sparkline-demo-chart {
  canvas{
    max-width: 100%;
  }
}

.table {
  &.table-hover {
    tbody {
        tr{
          &:hover {
          td {
            .icon-rounded-primary {
              background: theme-color(primary);
              @include transition(background .2s linear);
              h4 {
                color: $white;
              }  
            }
          }
        }  
      }  
    }
  }
}

.activity-feed-carousel {
  .carousel-control-prev,
  .carousel-control-next{
        top: 1.3rem;
        width: 1.6rem;
        height: 1.6rem;
        background: lighten(theme-color(primary), 33%);
        border-radius: 1.6rem;
        opacity: 1;
        &:hover {
          background: lighten(theme-color(primary), 28%);
        }
  }
  .carousel-control-prev {
    left: auto;
    right: 3.7rem;
    .carousel-control-prev-icon {
      background-image: none;
    }
    &:after {
      font-family: "Material Design Icons";
      content: '\F141';
      font-size: 1rem;
      color: theme-color(primary);
      line-height: 1.6rem;
      margin-right: 0.34rem;
    }
  }
  .carousel-control-next {
    left: auto;
    right: 1.681rem;
    .carousel-control-next-icon {
      background-image: none;
    }
    &:after {
      font-family: "Material Design Icons";
      content: '\F142';
      font-size: 1rem;
      color: theme-color(primary);
      line-height: 1.6rem;
      margin-right: 0.26rem;
    }
  }
}