@each $color, $value in $theme-colors {
    .hover-effect-list-#{$color} {
        .hover-effect-list-item {
            border-bottom: 1px solid $border-color;
            padding: 1.32rem 1.5rem;
            background-color: transparent;
            transition: background-color .2s ease-in-out;
            &:hover, &.active {
                background-color: $value;
                color: $white;
                box-shadow: $hover-list-box-shadow;
                -webkit-box-shadow: $hover-list-box-shadow;
                -moz-box-shadow: $hover-list-box-shadow;
                border-bottom: none;
                cursor: pointer;
            }
        }
    }
}